import styled from "styled-components";

import {
  DESKTOP_1200,
  TABLET_992,
  TABLET_768,
  MOBILE_460,
} from "../globals/sizes";

import { COLORS } from "../globals/colors";

export const HeroBackground = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(239, 239, 239, 1) 49%,
    rgba(236, 236, 236, 1) 50%
  );
  margin-top: 90px;
  @media (max-width: ${TABLET_992}px) {
    background: #eaeaea;
  }
`;

export const HeroContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: ${TABLET_992}px) {
    height: 100%;
    flex-direction: column;
  }
`;

export const HeroPictureContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeroPicture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    max-width: 1600px;
    object-position: 0%;

    @media (max-width: ${TABLET_992}px) {
      width: 120vw;
      height: unset;
      right: -99vw;
      top: -8vw;
    }
    @media (max-width: 600px) {
      width: 144vw;
    }
  }
`;

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 992px) {
    background: white;
    max-width: 100%;
    width: 100%;
    z-index: 5;
  }
`;

export const TextDiv = styled.div`
  margin-left: 60px;

  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    height: 100%;
  }

  @media (max-width: ${TABLET_992}px) {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 90%;
  }
`;

export const Title = styled.h1`
  color: ${COLORS.redTitle};
  font-size: 57px;
  line-height: 48px;
  font-family: 'Roboto';
  margin-top: 68px;

  & > span {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_992}px) {
    margin-top: 6vw;
    width: 100%;
    font-size: 38px;
  }
`;

export const IntroductionDiv = styled.div`
  color: ${COLORS.grayText};
  margin-top: 15px;
  font-size: 26px;
  line-height: 20px;
  font-weight: 300;
  font-family: 'Roboto';

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${MOBILE_460}px) {
    line-height: 26px;
    font-weight: 300;
    font-size: 19px;
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px auto;
  width: 80%;
  gap: 30px;
  @media (max-width: 1550px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width:  ${TABLET_992}px) {
    width: 95%;
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: repeat(1, 1fr);
}
`;

export const TeamSectionTitle = styled.h2`
  font-size: 36px;
  font-family: 'Roboto';
  color: ${COLORS.redTitle};
  margin: auto;
  padding-top: 90px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 30px;
  }
`

export const TeamSectionSubtitle = styled.h2`
  font-size: 26px;
  font-family: 'Roboto';
  color: ${COLORS.grayText};
  margin: auto;
  padding-top: 12px;
  padding-bottom: 15px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 20px;
  }
`

export const TeamCardContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TeamCard = styled.div`
  width: 288px;
  height: 397px;
  margin: 20px 0.3%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 17px;
  overflow: hidden;
  background-color: #eeeeee;
  :hover{
    ${({ index }) => ` 
        #team-info-${index} {
            height: 100%;
            background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
            opacity: 0.9;
        }
        #team-pic-${index} {
            height: 100%;
            width: 100%;
            & > img,
            & > source {
                height: 100%;
                width: 100%;
            }
        }
        #team-description-${index} {
            display: flex;
        }
    `}
  }
`;

export const TeamPic = styled.picture`
  & > img,
  & > source {
    width: 239px;
    height: 307px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    object-fit: cover;
    object-position: top;
    transition: height 0.15s ease-out;
  }
`;

export const TeamInfo = styled.div`
  height: -webkit-fill-available;
  text-align: center;
  background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0%
    0% no-repeat padding-box;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 94px;
  width: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  transition: height 0.25s ease-out;
  @media (max-width: ${MOBILE_460}px) {
    min-height: 70px;
  }
`;
export const TeamName = styled.h4`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 17px;
  line-height: 1.1rem;
  font-weight: bold;
  font-family: 'Roboto';
  margin-bottom: 3px;
  @media (max-width: ${TABLET_768}px) {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
`;

export const TeamTitle = styled.h4`
  text-align: center;
  width: 99%;
  color: white;
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 1rem;
  font-weight: thin;
  @media (max-width: ${TABLET_768}px) {
    font-size: 0.7rem;
    line-height: 0.8rem;
  }
`;

export const TeamDescription = styled.p`
    color: white;
    font-size: 0.88rem;
    line-height: 20px;
    font-family: 'Roboto';
    text-align: center;
    display: none;
    margin: 10px 45px 0 45px;
`
